var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "banner" },
        [
          _c(
            "van-swipe",
            { attrs: { autoplay: 3000 } },
            _vm._l(_vm.images, function (image, index) {
              return _c("van-swipe-item", { key: index }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: image,
                      expression: "image",
                    },
                  ],
                }),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "now-value" }, [
        _c("span", [_vm._v("当前数值" + _vm._s(_vm.$store.state.test.number))]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "van-button",
              {
                attrs: { type: "info", loading: _vm.loading, size: "small" },
                on: { click: _vm.onePlusAsync },
              },
              [_vm._v("异步+1")]
            ),
            _vm._v(" "),
            _c(
              "van-button",
              {
                attrs: { type: "primary", size: "small" },
                on: {
                  click: function ($event) {
                    return _vm.onePlus(1)
                  },
                },
              },
              [_vm._v("+1")]
            ),
            _vm._v(" "),
            _c(
              "van-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.twoPlus },
              },
              [_vm._v("+2")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "icon-list" },
        _vm._l(_vm.iconList, function (icon) {
          return _c("svg-icon", {
            key: icon,
            staticClass: "icon",
            attrs: { "icon-class": icon },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "buttons" },
        [
          _c(
            "van-button",
            { attrs: { type: "warning" }, on: { click: _vm.logout } },
            [_vm._v("退出登录" + _vm._s(_vm.name))]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            { attrs: { to: "/404" } },
            [
              _c("svg-icon", { attrs: { "icon-class": "404" } }),
              _vm._v("前往404页面"),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("footer-tabbar"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }